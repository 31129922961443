import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { HelmetDataQuery } from '../../utils/gatsby.types';
import Heading from '../heading/heading';
import * as styles from './contactForm.module.scss';

const ContactForm = (): JSX.Element => {
  const [formStatus, setFormStatus] = useState('');

  const formURL = useStaticQuery<HelmetDataQuery>(graphql`
    {
      meta: site {
        siteMetadata {
          contactForm
        }
      }
    }
  `).meta.siteMetadata.contactForm;

  const submitForm = (e): void => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = (): void => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setFormStatus('SUCCESS');
      } else {
        setFormStatus('ERROR');
      }
    };
    xhr.send(data);
  };

  return (
    <div className={styles.contact}>
      <div className={styles['contact-back']} id="contact">
        <div className={styles['contact-form-wrapper']}>
          <Container>
            <Row>
              <Col md={6}>
                <div className={styles['contact-form-left']}>
                  <div className={styles['contact-form-left-top-text']}>
                    Say hi!
                  </div>
                  <div className={styles['contact-form-left-bottom-text']}>
                    support@myhop.io
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className={styles['contact-form-card']}>
                  <Heading size="minor">Contact us</Heading>
                  <Form
                    method="POST"
                    action={formURL}
                    onSubmit={(e): void => submitForm(e)}
                  >
                    <FormGroup>
                      <Input
                        type="text"
                        id="contactGivenName"
                        name="givenName"
                        placeholder="First Name"
                        aria-label="First Name"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        id="contactGivenName"
                        name="lastName"
                        placeholder="Last Name"
                        aria-label="Last Name"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        id="contactPhoneNumber"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        aria-label="Phone Number"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="email"
                        id="contactEmail"
                        name="email"
                        placeholder="Email"
                        aria-label="Email"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="contactMessage"
                        id="contactMessage"
                        placeholder="Message"
                        aria-label="Message"
                        required
                      />
                    </FormGroup>
                    {formStatus === 'SUCCESS' ? (
                      <div>Form Submitted</div>
                    ) : (
                      <Button>Submit</Button>
                    )}
                    {formStatus === 'ERROR' ? (
                      <div>There was an issue processing the form</div>
                    ) : (
                      <></>
                    )}
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
