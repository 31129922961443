import React from 'react';
import { Container } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import * as styles from './iconBar.module.scss';
import Heading from '../heading/heading';
import { FixedImageQuery } from '../../utils/gatsby.types';

const IconBar = (): JSX.Element => {
  const data = useStaticQuery<FixedImageQuery>(graphql`
    {
      icon1: file(relativePath: { eq: "icons/bar/icon1.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon2: file(relativePath: { eq: "icons/bar/icon2.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon3: file(relativePath: { eq: "icons/bar/icon3.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  return (
    <Container className={styles['icon-container']} id="iconBar">
      <div className={styles.header}>
        <Heading size="sub">Start Earning Today</Heading>
      </div>
      <div className={styles['icon-wrapper']}>
        <div className={styles.icon}>
          <Img
            className={styles['gatsby-icon']}
            fixed={data.icon1.childImageSharp.fixed}
          />
          <div className={styles['icon-header']}>
            <span className={styles['icon-number']}>1.</span>
            &nbsp;Apply to Drive with HOP
          </div>
          <div className={styles['icon-copy']}>
            Register by downloading the HOP app and signup with your details in
            minutes. We will then verify your details before activating your
            account
          </div>
        </div>
        <div className={styles.icon}>
          <Img
            className={styles['gatsby-icon']}
            fixed={data.icon2.childImageSharp.fixed}
          />
          <div className={styles['icon-header']}>
            <span className={styles['icon-number']}>2.</span>
            &nbsp;Pick Up, Then Earn
          </div>
          <div className={styles['icon-copy']}>
            Once approved, you can view and book from a selection of cars from
            the HOP app in just a few taps
          </div>
        </div>
        <div className={styles.icon}>
          <Img
            className={styles['gatsby-icon']}
            fixed={data.icon3.childImageSharp.fixed}
          />
          <div className={styles['icon-header']}>
            <span className={styles['icon-number']}>3.</span>
            &nbsp;Enjoy the Flexibility
          </div>
          <div className={styles['icon-copy']}>
            Select cars from multiple locations and rent from one week to 12
            months, with no long-term commitments
          </div>
        </div>
      </div>
    </Container>
  );
};

export default IconBar;
