import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Heading from '../heading/heading';
import * as styles from './icon-grid.module.scss';

const IconGrid = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(relativePath: { eq: "icons/grid/icon_1.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon2: file(relativePath: { eq: "icons/grid/icon_2.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon3: file(relativePath: { eq: "icons/grid/icon_3.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon4: file(relativePath: { eq: "icons/grid/icon_4.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon5: file(relativePath: { eq: "icons/grid/icon_5.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon6: file(relativePath: { eq: "icons/grid/icon_6.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const icons = [
    {
      image: data.icon1.childImageSharp.fixed,
      copy: `Cars can be rented through the HOP app by booking at least 3 days prior to your pickup date.`,
    },
    {
      image: data.icon2.childImageSharp.fixed,
      copy: `All HOP drivers are paid a weekly cashback bonus throughout the rental duration so they can
             enjoy the most competitive rental prices.`,
    },
    {
      image: data.icon3.childImageSharp.fixed,
      copy: `With unlimited kilometres, you don’t need to worry about any limits or hidden surprises.`,
    },
    {
      image: data.icon4.childImageSharp.fixed,
      copy: `You will always be covered by insurance no matter where you go.`,
    },
    {
      image: data.icon5.childImageSharp.fixed,
      copy: `Meet new friends, fellow drivers, and neighbours as they ride along in your passenger seat`,
    },
    {
      image: data.icon6.childImageSharp.fixed,
      copy: `Access to Hertz cars through the HOP app lets you choose your dream car without the cost of ownership`,
    },
  ];
  return (
    <Row>
      <Container>
        <div className={styles['center-header']}>
          <Heading size="main">Earn Smarter with HOP</Heading>
        </div>
        <Row>
          {icons.map((icon) => (
            <Col lg={4} md={6} xs={12}>
              <div className={styles.icon}>
                <Img className={styles['gatsby-icon']} fixed={icon.image} />
                <div className={styles['icon-copy']}>{icon.copy}</div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Row>
  );
};

export default IconGrid;
