import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import {
  Carousel,
  CarouselIndicators,
  CarouselItem,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import { FixedImageQuery } from '../../utils/gatsby.types';
import Heading from '../heading/heading';
import * as styles from './gridContent.module.scss';

const GridContent = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const images = useStaticQuery<FixedImageQuery>(graphql`
    {
      corolla: file(relativePath: { eq: "cars/corolla.png" }) {
        childImageSharp {
          fixed(height: 200, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      toyota: file(relativePath: { eq: "cars/toyota.png" }) {
        childImageSharp {
          fixed(height: 200, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      nissan: file(relativePath: { eq: "cars/nissan.png" }) {
        childImageSharp {
          fixed(height: 200, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      phoneRental: file(relativePath: { eq: "phone-rental.png" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const items = [
    {
      key: 'a',
      tier: 'PLUS',
      image: images.corolla.childImageSharp.fixed,
      carName: 'Toyota Corolla Hatch (or Similar Hatch)',
      price: '$42 per Day',
    },
    {
      key: 'b',
      tier: 'PLUS',
      image: images.toyota.childImageSharp.fixed,
      carName: 'Toyota Corolla Sedan (or Similar Sedan)',
      price: '$49 per Day',
    },
    {
      key: 'b',
      tier: 'LUXE',
      image: images.nissan.childImageSharp.fixed,
      carName: 'Nissan Qashqai (or similar SUV)',
      price: '$49 per Day',
    },
  ];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length ? 3 - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
      >
        <Img className={styles['grid-car-image']} fixed={item.image} />
        <p>
          <div className={styles['grid-car-head']}>{item.tier}</div>
          <div className={styles['grid-car-body']}>{item.carName}</div>
          <div className={styles['grid-car-head']}>{item.price}</div>
          <div className={styles['grid-car-body']}>Unlimited KMs</div>
          <div className={styles['grid-car-head']}>Rental Period</div>
          <div className={styles['grid-car-body']}>7 Days to 12 Months</div>
        </p>
      </CarouselItem>
    );
  });

  return (
    <div className={styles['grid-background']}>
      <Container className={styles['grid-container']} id="gridContainer">
        <Row className={styles['grid-row']}>
          <Col md={6}>
            <Heading size="main">Drive More and Pay Less</Heading>
            <p>
              HOP has introduced a new platform where you can access rental cars
              with unlimited kilometres from dozens of locations across
              Australia and pickup customers without having to own a vehicle.
            </p>
            <p>
              There are no hidden costs or sneaky clauses, everything is
              transparent and simple. With HOP, we cover maintenance, cleaning,
              roadside assist and insurance whilst the car is used for
              ride-share purposes.
            </p>
            <p>
              When you initially rent a car through HOP, you will be charged the
              full rental price by Hertz at the time of pick-up. However, HOP
              will then deposit a weekly cashback to ensure you enjoy the full
              member benefits.
            </p>
          </Col>
          <Col md={{ size: 5, offset: 1 }}>
            <div className={styles['grid-square']}>
              <div className={styles['grid-square-border']}>
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  className={styles['carousel-slides']}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {slides}
                </Carousel>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={styles['grid-row']}>
          <Col md={6} className={styles['grid-phone-col']}>
            <Img fixed={images.phoneRental.childImageSharp.fixed} />
          </Col>
          <Col md={{ size: 6 }} className={styles['grid-phone-col-side']}>
            <Heading size="main">
              <>
                Get a Car Rental
                <br />
                Cost Estimate
              </>
            </Heading>
            <Table className={styles['cost-estimate']}>
              <tr>
                <th>&nbsp;</th>
                <th>PLUS</th>
                <th>PRO</th>
                <th>LUXE</th>
              </tr>
              <tr>
                <th>Weekly Cost</th>
                <td>$294</td>
                <td>$343</td>
                <td>$343</td>
              </tr>
              <tr>
                <th>HOP Weekly Cashback</th>
                <td>$65</td>
                <td>$68</td>
                <td>$68</td>
              </tr>
              <tr className={styles['final-cost']}>
                <th>Weekly Cost after Cashback</th>
                <td>$229</td>
                <td>$275</td>
                <td>$275</td>
              </tr>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GridContent;
