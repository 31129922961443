import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Container } from 'reactstrap';
import { FixedImageQuery } from '../../utils/gatsby.types';
import * as styles from './appCta.module.scss';

interface Props {
  children: JSX.Element | string;
  stores: {
    googlePlay: string;
    appStore: string;
  };
}

const AppCTA = (props: Props): JSX.Element => {
  const { children, stores } = props;
  const buttons = useStaticQuery<FixedImageQuery>(graphql`
    {
      appStore: file(relativePath: { eq: "app_store.png" }) {
        childImageSharp {
          fixed(height: 100, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      googlePlay: file(relativePath: { eq: "google_play.png" }) {
        childImageSharp {
          fixed(height: 100, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  return (
    <div className={styles['app-cta-wrapper']}>
      <Container>
        <div className={styles['app-cta-content']}>
          {children}
          {/*<div className={styles['app-cta-stores']}>*/}
          {/*  <a key={`_${stores.appStore}`} href={stores.appStore}>*/}
          {/*    <Img*/}
          {/*      className={styles.store}*/}
          {/*      fixed={buttons.appStore.childImageSharp.fixed}*/}
          {/*    />*/}
          {/*  </a>*/}
          {/*  <a key={`_${stores.googlePlay}`} href={stores.googlePlay}>*/}
          {/*    <Img*/}
          {/*      className={styles.store}*/}
          {/*      fixed={buttons.googlePlay.childImageSharp.fixed}*/}
          {/*    />*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </Container>
    </div>
  );
};

export default AppCTA;
