import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';
import React from 'react';
import { FluidImageQuery } from '../../utils/gatsby.types';
import styles from './heroImage.module.scss';

interface Props {
  children: JSX.Element;
}

const HeroImage = (props: Props): JSX.Element => {
  const { children } = props;
  const image: FluidObject = useStaticQuery<FluidImageQuery>(graphql`
    {
      file(relativePath: { eq: "hero_image.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `).file.childImageSharp.fluid;
  return (
    <div className={styles['hero-image']} id="heroImage">
      <BackgroundImage fluid={image} className={styles['gatsby-image']}>
        <div className={styles.children}>{children}</div>
      </BackgroundImage>
    </div>
  );
};

export default HeroImage;
