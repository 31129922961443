import React, { useState } from 'react';
import { Button, Col, Container, Row, UncontrolledCollapse } from 'reactstrap';
import Heading from '../heading/heading';
import * as styles from './faq.module.scss';

const FAQ = (): JSX.Element => {
  const [open, setOpen] = useState<string[]>([]);
  const faqData = [
    {
      key: 'a',
      question: 'How do I sign up with HOP?',
      answer: (): JSX.Element => (
        <div>
          Start off by downloading the HOP app from the Google Play Store or the
          App Store. To register you will require:
          <br />
          <ul>
            <li>Basic profile details</li>
            <li>Full Australian driver’s licence</li>
            <li>
              Driver Authority Card (or equivalent in states other than NSW)
            </li>
            <li>Bank details (to receive HOP’s weekly cashback)</li>
          </ul>
        </div>
      ),
    },
    {
      key: 'b',
      question: 'Is there a joining fee?',
      answer: (): JSX.Element => (
        <div>There is no joining or application fee with HOP.</div>
      ),
    },
    {
      key: 'c',
      question: 'Are any credit checks conducted to join HOP?',
      answer: (): JSX.Element => (
        <div>There are no credit checks performed.</div>
      ),
    },
    {
      key: 'd',
      question: 'How long in advance can I reserve a car through HOP?',
      answer: (): JSX.Element => (
        <div>
          Reservations must be made at least 3 days from the date of pick up.
        </div>
      ),
    },
    {
      key: 'e',
      question: 'What is the minimum duration I can rent a vehicle?',
      answer: (): JSX.Element => (
        <div>
          All cars booked through HOP have a one-week minimum rental period.
        </div>
      ),
    },
    {
      key: 'f',
      question: 'Can I return the vehicle earlier if I need to?',
      answer: (): JSX.Element => (
        <div>
          Drivers can return vehicles earlier provided they notify Hertz at
          least 3 days in advance.
        </div>
      ),
    },
    {
      key: 'g',
      question: 'What’s included?',
      answer: (): JSX.Element => (
        <div>
          <ul>
            <li>Online Customer Support</li>
            <li>Advanced driver training</li>
            <li>CTP insurance</li>
            <li>Rideshare insurance</li>
            <li>Servicing, maintenance, repairs</li>
            <li>24/7 roadside assistance</li>
            <li>Community events</li>
          </ul>
        </div>
      ),
    },
    {
      key: 'h',
      question: 'Are the cars registered?',
      answer: (): JSX.Element => (
        <div>
          Yes. All HOP cars are fully registered and covered by comprehensive
          insurance for rideshare use.
        </div>
      ),
    },
    {
      key: 'i',
      question: 'How much mileage is included?',
      answer: (): JSX.Element => (
        <div>All HOP cars come with unlimited kms.</div>
      ),
    },
    {
      key: 'j',
      question: 'Are there any excess charges?',
      answer: (): JSX.Element => (
        <div>
          There is an excess of $1,000 for all claims during ride-share usage.
          <br />
          For any incidents which occur during personal usage of the vehicle, a
          $4,500 standard excess is applicable. There is an option to reduce
          excess down to $500 to cover any personal usage of the vehicle for a
          fee of $12 per day.
        </div>
      ),
    },
    {
      key: 'k',
      question: 'There is something not right with my car, who do I call?',
      answer: (): JSX.Element => (
        <div>
          You will need to contact Hertz on the details stated on your
          reservation copy.
        </div>
      ),
    },
    {
      key: 'l',
      question: 'Is roadside assistance included?',
      answer: (): JSX.Element => (
        <div>
          Yes, roadside assistance is included for problems inherent to the
          vehicle. However, issues such as locked keys in vehicle, changing flat
          tyre etc, a $165.00 Roadside Assistance Fee is applicable.
        </div>
      ),
    },
    {
      key: 'm',
      question: 'What if I’m in an accident?',
      answer: (): JSX.Element => (
        <div>
          If you are involved in an accident, it is important that you follow
          the correct procedure. These simple steps will assist you at the scene
          of an accident:
          <ol>
            <li>
              Determine whether any passenger in your car or the other car/s has
              suffered any personal injury.
            </li>
            <li>
              For Police, Ambulance or Fire and Rescue call 000 or 112 from your
              mobile phone.
            </li>
            <li>
              Do not admit liability or offer payment to any party concerned.
            </li>
            <li>
              Give your name, address, licence number, registration number and
              insurance details to the other people involved in the accident.
              Obtain similar details from them.
            </li>
            <li>
              Make every effort to find a witness to the accident and obtain
              their details.
            </li>
            <li>
              Take notes/photos of the accident scene, cross streets, the
              direction of travel, car damage and so on, as this information
              will help you complete an insurance claim form.
            </li>
            <li>
              Police reporting guidelines vary in each state. Make sure you are
              aware of your requirements.
            </li>
            <li>
              Report the accident to HOP as soon as possible and in any event
              within 24 hours.
            </li>
          </ol>
          You can contact Hertz to support you with the following:
          <ul>
            <li>Towing from the scene and insurance claims assistance</li>
            <li>Pick up of roadworthy cars needing repair</li>
            <li>Arrange car repairs</li>
          </ul>
        </div>
      ),
    },
    {
      key: 'n',
      question: 'What do I do if I have damaged the car?',
      answer: (): JSX.Element => (
        <div>
          If the car is lost, stolen, abandoned or damaged during the rental
          period, you will need to contact Hertz.
        </div>
      ),
    },
    {
      key: 'o',
      question: 'Can I use my own toll tag?',
      answer: (): JSX.Element => (
        <div>
          You must arrange your own toll tag and fit the tag to your rental car.
        </div>
      ),
    },
    {
      key: 'p',
      question: 'When do I provide my credit card details for payment?',
      answer: (): JSX.Element => (
        <div>
          Payment are made at the Hertz location at the time of pick up.
        </div>
      ),
    },
    {
      key: 'q',
      question: 'Do I get charged monthly or weekly?',
      answer: (): JSX.Element => (
        <div>You will be charged weekly in advance.</div>
      ),
    },
    {
      key: 'm',
      question: 'Do I need to pay a bond?',
      answer: (): JSX.Element => (
        <div>
          Yes. There is a $500 bond amount which will be released or deducted
          from outstanding charges at termination of rental.
        </div>
      ),
    },
    {
      key: 'r',
      question: 'Do I need to pay for fuel?',
      answer: (): JSX.Element => (
        <div>Yes. You will need to cover your fuel expenses.</div>
      ),
    },
    {
      key: 's',
      question: 'Can I cancel?',
      answer: (): JSX.Element => (
        <div>
          You will need to contact Hertz on 13 30 39 if you are unable to
          collect the car.
        </div>
      ),
    },
    {
      key: 't',
      question: 'What do I need to know before I return the car?',
      answer: (): JSX.Element => (
        <div>
          When you return the car, please ensure:
          <ul>
            <li>You return the car and keys</li>
            <li>The car contains a full tank of petrol</li>
            <li>All rubbish has been removed</li>
            <li>All belongings are removed</li>
            <li>
              There is a one-hour grace period, after which additional charges
              will apply
            </li>
          </ul>
        </div>
      ),
    },
    {
      key: 'u',
      question:
        'Do I need to fill up the petrol tank before I return\n' + 'the car?',
      answer: (): JSX.Element => (
        <div>
          Yes, you will need to return the car with a full tank of petrol.
          Failure to do so will result in a fuel charge being added.
        </div>
      ),
    },
  ];

  return (
    <div id="faq" className={styles['faq-wrapper']}>
      <Container className={styles['faq-container']}>
        <div className={styles.header}>
          <Heading size="minor">Frequently asked questions</Heading>
        </div>
        {faqData.map((fd, index) => (
          <Row key={fd.key}>
            <Col md={{ size: 10, offset: 1 }}>
              <div
                className={
                  styles[open.includes(fd.key) ? 'faq-card-open' : 'faq-card']
                }
              >
                <Button
                  id={fd.key}
                  onClick={() => {
                    const itemIndex = open.indexOf(fd.key);
                    itemIndex >= 0
                      ? setOpen(open.filter((key) => key !== fd.key))
                      : setOpen(open.concat(fd.key));
                  }}
                >
                  <span>{fd.question}</span>
                </Button>
                <UncontrolledCollapse toggler={`#${fd.key}`}>
                  <div className={styles['faq-answer']}>{fd.answer()}</div>
                </UncontrolledCollapse>
              </div>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};

export default FAQ;
