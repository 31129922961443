import 'bootstrap/dist/css/bootstrap.css';
import '../components/index.scss';
import React from 'react';
import { Container } from 'reactstrap';
import AppCTA from '../components/app-cta/appCTA';
import ContactForm from '../components/contact-form/contactForm';
import DownloadButton from '../components/download-button/download-button';
import FAQ from '../components/faq/faq';
import Footer from '../components/footer/footer';
import GridContent from '../components/grid-content/gridContent';
import Head from '../components/head/head';
import HeroImage from '../components/hero-image/heroImage';
import IconBar from '../components/icon-bar/iconBar';
import IconGrid from '../components/icon-grid/icon-grid';
import NavBar from '../components/nav-bar/navBar';
import PhoneCarousel from '../components/phone-carousel/phoneCarousel';

const Home = (): JSX.Element => {
  return (
    <Container fluid>
      <Head />
      <NavBar home />
      <HeroImage>
        <AppCTA stores={{ googlePlay: '/#', appStore: '/#' }}>
          <>
            One week free car rental when you sign up with HOP, includes
            unlimited KMs and no lock-in contract*
            <div style={{ fontSize: 'max(0.25em, 11px)', width: '75%' }}>
              *HOP will reimburse the full rental cost through a cashback paid
              to your nominated account in 2 business days (excludes fuel and
              toll fees)
            </div>
          </>
        </AppCTA>
      </HeroImage>
      <IconBar />
      <GridContent />
      <PhoneCarousel />
      <IconGrid />
      <DownloadButton text="GET APP" />
      <FAQ />
      <ContactForm />
      <Footer />
    </Container>
  );
};

export default Home;
