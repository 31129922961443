import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FixedImageQuery, FluidImageQuery } from '../../utils/gatsby.types';
import * as styles from './phoneCarousel.module.scss';

const PhoneCarousel = (): JSX.Element => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const data = useStaticQuery<FixedImageQuery & FluidImageQuery>(graphql`
    {
      step_one: file(relativePath: { eq: "phones/step_one.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      step_two: file(relativePath: { eq: "phones/step_two.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      step_three: file(relativePath: { eq: "phones/step_three.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      backgroundImage: file(relativePath: { eq: "satellite.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const carouselContent = [
    {
      image: data.step_one.childImageSharp.fixed,
      text: 'Chose your pickup location, time & date',
      sub: 'Find your nearest rental location',
    },
    {
      image: data.step_two.childImageSharp.fixed,
      text: 'Chose your car',
      sub: 'Select from our list of vehicles',
    },
    {
      image: data.step_three.childImageSharp.fixed,
      text: 'Drop off your rental when your finished',
      sub: 'Drop off your vehicle at your designated drop off location',
    },
  ];

  return (
    <Row id="howItWorks">
      <Container
        fluid
        className={styles['phone-carousel-container']}
        id="phoneCarousel"
      >
        <Row>
          <BackgroundImage
            fluid={data.backgroundImage.childImageSharp.fluid}
            className={styles['background-image']}
          >
            <div className={styles['phone-carousel-footer']}>
              <Container>
                <Row className={styles['phone-carousel-footer-row']}>
                  <Col
                    lg={{ size: 4, order: 1 }}
                    xs={{ size: 12, order: 2 }}
                    className={styles['phone-carousel-footer-left']}
                  >
                    How Driving Works
                  </Col>
                  <Col
                    lg={{ size: 4, order: 2 }}
                    xs={{ size: 12, order: 1 }}
                    className={styles['phone-carousel-footer-image']}
                  >
                    {carouselContent.map((content, i) => (
                      <Img
                        className={
                          styles[
                            currentSlide === i
                              ? 'phone-carousel-footer-image-container'
                              : 'phone-carousel-footer-image-container-hidden'
                          ]
                        }
                        fixed={content.image}
                      />
                    ))}
                  </Col>
                  <Col
                    lg={{ size: 4, order: 3 }}
                    xs={{ size: 12, order: 3 }}
                    className={styles['phone-carousel-footer-right']}
                  >
                    {carouselContent.map((content, i) => (
                      <p
                        className={
                          styles[
                            currentSlide === i
                              ? 'phone-carousel-footer-right-text'
                              : 'phone-carousel-footer-right-text-hidden'
                          ]
                        }
                      >
                        <span
                          className={
                            styles['phone-carousel-footer-right-text-index']
                          }
                        >
                          {i + 1}
                          &#46;&nbsp;
                        </span>
                        {content.text}
                        <div
                          className={
                            styles['phone-carousel-footer-right-text-light']
                          }
                        >
                          {content.sub}
                        </div>
                      </p>
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={{ size: 4, offset: 4 }}
                    className={styles['phone-carousel-buttons']}
                  >
                    {carouselContent.map((button, i) => (
                      <button
                        className={
                          styles[
                            currentSlide === i
                              ? 'phone-carousel-button-active'
                              : 'phone-carousel-button'
                          ]
                        }
                        onClick={(): void => {
                          setCurrentSlide(i);
                        }}
                        onKeyPressCapture={(): void => {
                          setCurrentSlide(i);
                        }}
                        type="button"
                      >
                        {i + 1}
                      </button>
                    ))}
                  </Col>
                </Row>
              </Container>
            </div>
          </BackgroundImage>
        </Row>
      </Container>
    </Row>
  );
};

export default PhoneCarousel;
